.ModalContainer {
  height: calc(100vh - env(safe-area-inset-bottom));
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  min-width: 360px;
}

.heightfill {
  min-height: -webkit-fill-available;
  min-height: stretch;
}

.BgOpacity55 {
  background-color: rgba(0, 0, 0, 0.55);
}
@media (max-width: 1023px) {
  .ModalWrapper {
    width: calc(100% - 16px);
    min-width: 320px;
  }
}

.section1_mainlogo_pulse {
  position: relative;
  z-index: 1;

  > img {
    z-index: 1;
  }

  &::before,
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    transform: scale(0.5);
    transform-origin: center center;
    animation: pulse-me 3s linear infinite;
    z-index: -1;
  }

  &::after {
    animation-delay: 1.5s;
  }

  @keyframes pulse-me {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    50% {
      opacity: 0.2;
      transform: scale(2);
    }
    70% {
      opacity: 0.09;
    }
    100% {
      transform: scale(4);
      opacity: 0;
    }
  }
}
