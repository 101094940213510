.EducationCard {
  width: calc(50% - 16px);
  height: 310px;
  flex-shrink: 0;
  border-radius: 16px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}
@media (max-width: 1023px) {
  .EducationCard {
    width: calc(50% - 8px);
    height: 302px;
  }
}

@media (max-width: 767px) {
  .EducationCard {
    width: 100%;
    height: 163px;
  }
}
