.container {
  z-index: 50;
  position: absolute;
  top: 60px;
  right: 0;
  width: 240px;
  color: #222222;
}

.profile {
  width: 50px;
  height: 50px;
}

.CircleDivider::after {
  content: '';
  display: inline-block;
  vertical-align: 2px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: rgb(196, 196, 196);
  margin: 0 8px;
}
.newIcon::after {
  content: '';
  display: none;
  position: absolute;
  top: 0;
  right: -12px;
  width: 8px;
  height: 8px;
  background-color: rgba(243, 154, 39, 1);
  border-radius: 50%;
}
.mobileWrap {
  min-width: 320px;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  min-height: stretch;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 1024px) {
  .container {
    top: 0;
    width: 100%;
    overflow: auto;
    height: calc(100vh - env(safe-area-inset-bottom));
    height: calc(var(--vh, 1vh) * 100);
    &::-webkit-scrollbar {
      width: 4px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      margin-top: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: #d4d4d4;
      border-radius: 2px;
      height: 100px;
    }
    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
    }
  }
}
