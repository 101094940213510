.Tooltip {
  top: 13px;
  right: -65px;
}

.TooltipMotion {
  position: absolute;
  right: 23px;
  animation: motion 0.8s ease-in 0s infinite alternate;
  top: 45px;
}

.TooltipArrow {
  position: absolute;
  top: 5px !important;
  right: 10px;
}

@keyframes motion {
  0% {
    top: 45px;
  }
  100% {
    top: 51px;
  }
}

@media (max-width: 768px) {
  .Tooltip {
    right: -25px;
  }
  .TooltipArrow {
    right: 30px;
  }
}
