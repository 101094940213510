.corp::after {
  content: '';
  display: inline-block;
  width: 1px;
  height: 10px;
  margin: 0 8px;
  background-color: #737373;
}
.logoImg {
  height: 38px;
}
@media (max-width: 1023px) {
  .logoImg {
    height: 33px;
  }
}
@media (max-width: 767px) {
  .corp:last-child::after {
    display: none;
  }
}
